<template>
  <span v-if="isYourListing" data-testid="listing-audience-banner-label">
    {{
      $t(`audience.label`, {
        party: $t(`audience.${listing.type}.${listing.audience}`),
      })
    }}
  </span>
</template>
<script setup lang="ts">
import { useUserStore } from '@/stores';
import { ListingPreview } from '@/types';
import { computed } from 'vue';

const props = defineProps<{
  listing: ListingPreview;
}>();

const userStore = useUserStore();

const isYourListing = computed(() => {
  return userStore.isMyCompany(props.listing.owner.id);
});
</script>
