import { useUserStore } from '@/stores';
import { ListingPreview, NegotiationPreview } from '@/types';
import { computed } from 'vue';

export function useCounterParty(
  listing: ListingPreview,
  negotiation?: NegotiationPreview
) {
  const userStore = useUserStore();

  return computed(() => {
    if (negotiation) {
      return userStore.isMyCompany(listing.owner.id)
        ? negotiation.owner
        : listing.owner;
    }
    return listing.owner;
  });
}
