<template>
  <p v-if="containsAllFactories" class="text-sm/6">
    {{
      $t('company-factory.approved-product-origin.all-approved', {
        separator: isYourListing ? ',' : '',
      })
    }}
    <ListingAudienceBanner :listing="listing" />
  </p>
  <template v-else>
    <p class="mb-2 text-sm/6 text-slate-500">
      {{
        listing.type === 'OFFER'
          ? $t('company-factory.approved-product-origin.banner-title', {
              count: factories.length,
              separator: isYourListing ? ',' : '',
            })
          : $t(
              'company-factory.approved-product-origin.banner-title-approved',
              { count: factories.length, separator: isYourListing ? ',' : '' }
            )
      }}
      <ListingAudienceBanner :listing="listing" />
    </p>
    <div
      v-for="(companies, locationId) in groupedFactories"
      :key="locationId"
      class="mb-2 grid grid-cols-3 gap-4 text-sm/6"
    >
      <p class="mr-3 text-slate-500">
        {{ shipmentStore.getLocationById(locationId)?.name }}
      </p>
      <div class="col-span-2 flex flex-col">
        <template v-for="(codes, companyName) in companies" :key="companyName">
          <div>
            <span class="text-gray-800">{{ `${companyName}: ` }}</span>
            <span class="text-slate-500">{{ codes.join(', ') }}</span>
          </div>
        </template>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import ListingAudienceBanner from '@/components/ListingAudienceBanner.vue';
import { useGroupedFactories } from '@/composables';
import factoriesForListing from '@/filters/factory/factories-for-listing';
import {
  useApprovedFactoryStore,
  useCompanyFactoryStore,
  useShipmentStore,
  useUserStore,
} from '@/stores';
import { Factory, ListingPreview } from '@/types';
import { ReadCompanyDeal } from '@/types/deal';
import { computed } from 'vue';

const userStore = useUserStore();
const approvedFactoryStore = useApprovedFactoryStore();
const companyFactoryStore = useCompanyFactoryStore();
const shipmentStore = useShipmentStore();

const props = defineProps<{
  listing: ListingPreview;
  deal?: ReadCompanyDeal;
}>();

const factories = computed<Factory[]>(() => {
  if (props.deal) {
    return props.deal.factories;
  }

  if (props.listing.type === 'BID') {
    return isYourListing.value
      ? factoriesForListing(
          approvedFactoryStore.approvedFactories,
          props.listing
        )
      : factoriesForListing(companyFactoryStore.factories, props.listing);
  }

  if (props.listing.type === 'OFFER') {
    return isYourListing.value
      ? factoriesForListing(companyFactoryStore.factories, props.listing)
      : factoriesForListing(
          approvedFactoryStore.approvedFactories,
          props.listing
        );
  }

  return [];
});

const groupedFactories = useGroupedFactories(factories);

const isYourListing = computed(() => {
  return userStore.isMyCompany(props.listing.owner.id);
});

const containsAllFactories = computed(() => {
  if (isYourListing.value || props.listing.type === 'OFFER') {
    return false;
  }

  return companyFactoryStore.factories.every((factory) =>
    factories.value.map((factory) => factory.id).includes(factory.id)
  );
});
</script>
