import { useApi } from '@/api';
import forAnyParty from '@/filters/factory/for-any-party';
import notForTrader from '@/filters/factory/not-for-trader';
import onlyForTrader from '@/filters/factory/only-for-trader';
import { useUserStore } from '@/stores';
import {
  BuyerApprovedFactory,
  CompanyType,
  Factory,
  ListingAudience,
  Product,
} from '@/types';
import { captureException, withScope } from '@sentry/vue';
import { isEmpty } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useApprovedFactoryStore = defineStore('approvedFactory', () => {
  const api = useApi();
  const buyerApprovedFactories = ref<BuyerApprovedFactory[]>([]);
  const approvedFactories = computed<Factory[]>(() =>
    buyerApprovedFactories.value.map(
      (approvedFactory) => approvedFactory.factory
    )
  );

  async function load() {
    if (!useUserStore().hasPermission('CUSTOMER/APPROVED_FACTORY.GET')) {
      return;
    }

    await api.company
      .listApprovedFactories()
      .then(
        (loadedFactories) => (buyerApprovedFactories.value = loadedFactories)
      )
      .catch((error) =>
        withScope((scope) => {
          scope.setLevel('warning');
          captureException(
            new Error('Failed to load approved factories', { cause: error })
          );
        })
      );
  }

  function filterByProductAndAudience(
    product: Product,
    audience: ListingAudience
  ) {
    return buyerApprovedFactories.value
      .filter((approvedFactory) => {
        const { negotiationType, approvedProducts } = approvedFactory;

        const hasProduct = approvedProducts.includes(product.id);

        const isAudienceMatch =
          audience === 'ANY_PARTY' ||
          (audience === 'DIRECT_PARTY' && negotiationType !== 'INDIRECT');

        return hasProduct && isAudienceMatch;
      })
      .map((approvedFactory) => approvedFactory.factory); // Map to get the factory details only
  }

  function hasAnyByProduct(
    factoryIds: number[],
    productId: number,
    companyTypes: CompanyType[]
  ): boolean {
    if (isEmpty(factoryIds)) {
      return false;
    }

    return buyerApprovedFactories.value
      .filter((approvedFactory) => {
        return (
          onlyForTrader(approvedFactory.negotiationType, companyTypes) ||
          notForTrader(approvedFactory.negotiationType, companyTypes) ||
          forAnyParty(approvedFactory.negotiationType)
        );
      })
      .some((buyerFactory) => {
        return (
          factoryIds.includes(buyerFactory.factory.id) &&
          buyerFactory.approvedProducts.includes(productId)
        );
      });
  }

  function clear() {
    buyerApprovedFactories.value = [];
  }

  return {
    approvedFactories,
    buyerApprovedFactories,
    clear,
    filterByProductAndAudience,
    hasAnyByProduct,
    load,
  };
});
