import { useUserStore } from '@/stores';
import { ListingPreview } from '@/types';

export function useIsApprovedListing(listing: ListingPreview): boolean {
  const company = useUserStore().company;

  if (!company || company.id === listing.owner.id) {
    return false;
  }

  const matchedFactories = listing.matches[company.id] ?? [];

  return matchedFactories.length > 0;
}
