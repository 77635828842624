<template>
  <header class="border-b border-vesper-mud">
    <div class="flex w-full items-center px-6 py-5">
      <h2 v-if="!isChildRoute" class="font-medium leading-none">
        {{ $t([`pages.${String(route.name)}`]) }}
      </h2>

      <h2
        v-else-if="route.name === 'overview' || route.name === 'listings'"
        class="border-r border-slate-300 pr-5 font-medium"
      >
        {{ productStore.product?.name }}
      </h2>

      <TestingPhaseIndicator v-if="activeMarket" :market="activeMarket" />
      <MarketFilter
        v-if="productStore.product"
        :key="productStore.product.slug"
        :product="productStore.product"
        class="ml-4"
      />

      <div v-if="userStore.company" class="ml-auto flex space-x-3">
        <template v-if="!isProduction">
          <CompanyTypeIndicator
            v-for="type in userStore.company.types"
            :key="type"
            :by-you="false"
            :type="type"
          />
        </template>
        <NextMarketEvent />
        <MarketIndicator />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import CompanyTypeIndicator from '@/components/CompanyTypeIndicator.vue';
import MarketFilter from '@/components/MarketFilter.vue';
import MarketIndicator from '@/components/MarketIndicator.vue';
import NextMarketEvent from '@/components/NextMarketEvent.vue';
import TestingPhaseIndicator from '@/components/TestingPhaseIndicator.vue';
import { useMarketStore, useProductStore, useUserStore } from '@/stores';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const userStore = useUserStore();
const productStore = useProductStore();
const marketStore = useMarketStore();

const activeMarket = computed(() => marketStore.market);

const isChildRoute = computed(() => {
  const segments = route.path.split('/');
  return segments.length > 2;
});

const isProduction = import.meta.env.VITE_ENV === 'production';
</script>
