import { useUserStore } from '@/stores';
import { ListingPreview } from '@/types';
import { computed } from 'vue';

export function useShowProductOrigin(listing: ListingPreview) {
  const userStore = useUserStore();

  const isYourListing = computed(() => {
    return userStore.isMyCompany(listing.owner.id);
  });

  return computed(() => {
    // If the user owns the listing, always show the product origin
    if (
      isYourListing.value ||
      userStore.user?.company.types.includes('TRADER')
    ) {
      return true;
    }

    // If listing type is offer and the user is a buyer and doesn't own the listing, never show the product origin
    if (
      listing.type === 'OFFER' &&
      userStore.user?.company.types.includes('BUYER')
    ) {
      return false;
    }

    // Check if the listing type is 'BID'
    return listing.type === 'BID';
  });
}
