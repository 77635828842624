import { useApi } from '@/api';
import { useUserStore } from '@/stores';
import { Factory, SellerFactory } from '@/types/company';
import { Product } from '@/types/product';
import { captureException, withScope } from '@sentry/vue';
import { isEmpty } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useCompanyFactoryStore = defineStore('companyFactory', () => {
  const api = useApi();

  const companyFactories = ref<SellerFactory[]>([]);
  const factories = computed<Factory[]>(() =>
    companyFactories.value.map((sellerFactory) => sellerFactory.factory)
  );

  async function load() {
    if (!useUserStore().hasPermission('CUSTOMER/FACTORY.GET')) {
      return;
    }

    await api.company
      .listFactories()
      .then((loadedFactories) => (companyFactories.value = loadedFactories))
      .catch((error) =>
        withScope((scope) => {
          scope.setLevel('warning');
          captureException(
            new Error('Failed to load company factories', { cause: error })
          );
        })
      );
  }

  function filterByProduct(product: Product) {
    return factories.value.filter((factory) =>
      factory.products.includes(product.id)
    );
  }

  function hasAnyByProduct(
    factoryIds: number[],
    productId: number,
    listingOwner: number
  ) {
    if (isEmpty(factoryIds)) {
      return false;
    }

    return companyFactories.value
      .filter((factory) => {
        return Array.from(factory.approvedBy).includes(listingOwner);
      })
      .some((sellerFactory) => {
        return (
          factoryIds.includes(sellerFactory.factory.id) &&
          sellerFactory.factory.products.includes(productId)
        );
      });
  }

  function clear() {
    companyFactories.value = [];
  }

  return {
    clear,
    companyFactories,
    factories,
    filterByProduct,
    hasAnyByProduct,
    load,
  };
});
